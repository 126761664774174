<template>
<div class="main_content ">
            <div class="nav_filter">
                <div class="nav_wrapper">
                    <div  class="form-group group_filtro" style="min-width: 0;">
                        <div class="input-group">
                            <label class="mb-0 mt5 mr-2  d-none d-md-flex">{{$t('filtros.mostrar')}}</label>
                            <div class="input-group-append">
                                <select class="form-control" id="comboMostrar" v-model='orderBy'>
                                    <option value='relevance'>{{$t('filtros.relevance')}}</option>
                                    <option value='byname'>{{$t('filtros.alfa')}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <!--
                     <ul class="list-inline button_group" >
                        <li class="list-inline-item">
                            <button @click="switchContactView('directorio')" :class="activeContactos=='directorio'?'btn btn-secondary btn-icon btn-sm i_directorio w-100 active':'btn btn-secondary btn-icon btn-sm i_directorio w-100 '">
                                <span>{{$t('login.empresa')}}</span>
                            </button>
                        </li>
                        <li class="list-inline-item">
                            <button @click="switchContactView('partners')" :class="activeContactos=='partners'?'btn btn-info btn-icon btn-sm i_reseller w-100 active':'btn btn-info btn-icon btn-sm i_reseller w-100 '">
                                <span>{{$t('login.partner')}}</span>
                            </button>
                        </li>
                        
                    </ul>-->
                </div>
                
            </div>


            <div class="wrapper pt20 pb20 pl20 pr20 scolable" >
                    <h6 class="text_results mb-0" v-if="partners_filtered.length>0">{{$t('general.mostrando')}} {{partners_filtered.length}} {{$t('general.results')}} </h6> 
                    <h6 v-else class="text_results mb-0">{{$t('general.nose')}}</h6> 
                 
                    <ul class="partners_list">
                        <template v-for="user in partners_filtered">
                            <li :key="user.uid+$util.randomchar()" v-if="user.isPartner"  >
                                <mPartner :user='user'/>
                            </li>
                        </template>
                         
                    </ul>

            </div>
</div>
</template>



<script>


import mLinckard from "@/layouts/main_Directorio/main/miniLinckard.vue";
import mPartner from "@/layouts/main_Directorio/main/miniPartner.vue";
import mDirectory from "@/layouts/main_Directorio/main/miniDirectory.vue";
import mMulticard from "@/layouts/main_Directorio/main/miniMulticard.vue";



export default {
    props:['users','search'],
    data() {
        return{
            laLista:this.users,
            searchString:this.search,
            //varios
            orderBy:this.$store.state.orderBy,
            loading:false,
            activeContactos:this.$store.state.contactView,
        }
    },
    methods:{
        switchContactView(comp){
            this.activeContactos=comp
            this.$store.state.contactView=comp
            this.$bus.$emit('contacts_switchContactView',comp)
        }
    },
    computed:{
        partners_filtered(){
            var dis=this
            let devolver=this.laLista.filter(u => { return u.isPartner})
            if(this.orderBy=='byname'){
                devolver=devolver.sort((a, b) => {
                    let ca=a.account_details.account_name
                    let cb=b.account_details.account_name
                    if(a.linckards)ca=a.linckards[0].info.nombre
                    if(b.linckards)cb=b.linckards[0].info.nombre
                    return ca.localeCompare(cb)
                })
            }else if(this.orderBy=='relevance'){
                devolver=devolver.sort((a, b) =>  a.id - b.id)
            }

            if(this.searchString){
                devolver= devolver.filter(u => {
                    let volver=true
                    if(u.linckards) volver=volver && (u.linckards[0].info.nombre.toLowerCase().includes(dis.searchString.toLowerCase()))//busca linckards
                    volver = volver && u.account_details.account_name.toLowerCase().includes(dis.searchString.toLowerCase())//busca linckards
                    return volver
                })
            }

            return devolver
        }
    },
    watch:{
        orderBy(val){
            this.$store.state.orderBy=val
        }
    },
    components:{
        mLinckard,  mPartner,mDirectory,mMulticard
    }
}

</script>


<style>

/*Para que aparezca la manito en todos*/
ul.directorio_list li,ul.directorio_list button{
cursor:pointer !important;
}


.panel .nav_filter .nav_wrapper{
   /*fix a un borde blanco que aparecia abajo de la barra en el direcotrio*/
   border:0px;
}


.firma_user_name{
    /*fix nombres*/
    max-width:none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/*pagination mobile*/
@media (max-width: 799px) {

    .pagination{
        margin-bottom: 100px !important;
    }
    .group_filtro{
        padding-top: 60px!important;
    }
}
</style>