<template>
<div class="main_content ">
            <div class="nav_filter">
                <div class="nav_wrapper">
                    <div class="form-group group_filtro" style="min-width: 0;">
                        <div class="input-group">
                            <label class="mb-0 mt5 mr-2">{{$t('filtros.mostrar')}}</label>
                            <div class="input-group-append">
                                <select class="form-control" id="comboMostrar" v-model='orderBy'>
                                    <option value='relevance'>{{$t('filtros.relevance')}}</option>
                                    <option value='byname'>{{$t('filtros.alfa')}}</option>
                                </select>
                            </div>
                            

                        </div>
                    </div>
                </div>

            </div>


            <div :key="orderBy" class="wrapper pt20 pb20 pl20 pr20 scolable" >
                    <ais-infinite-hits v-if="orderBy=='byname'" :transform-items="orderbyName">
                     <template slot-scope="{ items }">
                            <h6 class="text_results mb-0" v-if="items.length>0">{{$t('general.mostrando')}} {{items.length}} {{$t('general.results')}} </h6> 
                            <h6 v-else class="text_results mb-0">{{$t('general.nose')}}</h6> 
                            <ul class="directorio_list">
                                <template v-for="user in items" >
                                    <li :key="user.uid" v-if="user.linckards && user.activeLincs<=1 "  >
                                        <mLinckard :linc='user.linckards[0]'  :user="user"  :users="items" /> 
                                    </li>
                                    <li :key="user.uid"  v-else-if="(user.sectors && user.sectors.length>0) && user.activeLincs>=1 ">
                                        <mDirectory :user='user' :users="items" /> 
                                    </li>
                                    <li :key="user.uid" v-else-if="user.activeLincs>1 "  >
                                        <mMulticard :user='user'  :users="items" /> 
                                    </li>
                                </template>
                            </ul>
                            
                        </template>
                    </ais-infinite-hits>
                    <ais-infinite-hits v-else :transform-items="orderbyName">
                     
                     <template slot-scope="{ items,refineNext }" >
                            <h6 class="text_results mb-0" v-if="items.length>0">{{$t('general.mostrando')}} {{items.length}} {{$t('general.results')}} </h6> 
                            <h6 v-else class="text_results mb-0">{{$t('general.nose')}}</h6> 
                            <ul class="directorio_list">
                                <template v-for="user in items" >
                                      <li :key="user.uid" v-if="user.linckards && user.activeLincs<=1 && user.linckards[0] "  >
                                            <mLinckard :linc='user.linckards[0]'  :user="user"  :users="items" /> 
                                        </li>
                                        <li :key="user.uid"  v-else-if="(user.sectors && user.sectors.length>0) && user.activeLincs>=1 ">
                                            <mDirectory :user='user' :users="items" /> 
                                        </li>
                                        <li :key="user.uid" v-else-if="user.activeLincs>1 "  >
                                            <mMulticard :user='user'  :users="items" /> 
                                        </li>
                                </template>

                                   
                                
                            </ul>
                            <div v-if="items.length>0 && items.length>=20" class="mas_contenedor">
                                <button class="firma_url manito w100"  @click="refineNext">
                                    {{$t('general.showmore')}}
                                </button>
                            </div>
                        </template>
                    </ais-infinite-hits>
                 

            </div>
</div>
</template>



<script>

import mLinckard from "@/layouts/main_Directorio/main/miniLinckard.vue";
import mPartner from "@/layouts/main_Directorio/main/miniPartner.vue";
import mDirectory from "@/layouts/main_Directorio/main/miniDirectory.vue";
import mMulticard from "@/layouts/main_Directorio/main/miniMulticard.vue";


export default {
    data() {
        return{
            //varios
            orderBy:'relevance',
            loading:false,
            end:false,
        }
    },
    methods: {
        orderbyName(items) {
            if(this.orderBy=='byname'){
                return items.sort((a, b) => {
                    let ca=a.account_details.account_name
                    let cb=b.account_details.account_name
                    return ca.localeCompare(cb)
                })
            }else{
                for (var j, x, i = items.length;i;j = Math.floor(Math.random() * i), x = items[--i], items[i] = items[j], items[j] = x);
                return items;
            }
            
        },
    },
    components:{
        mLinckard,  mPartner,mDirectory,mMulticard
    },
}

</script>


<style>
.mas_contenedor{
width:100% !important;
margin-top:40px !important;
padding-bottom:50px !important;

}
@media (max-width: 799px) {
    .group_filtro{
        padding-top: 60px!important;
    }

.mas_contenedor{
padding-bottom:200px !important;
    }
}
.mas_contenedor .firma_url{
width:50%;
background:#EDEDED !important;
color:#438FDE !important;
font-size:20px;
display:block !important;
margin:0 auto !important;
border:0px !important;

}

@media (max-width: 799px) {
.mas_contenedor .firma_url{
    width:100% !important;
}
}
/*Para que aparezca la manito en todos*/
ul.directorio_list li,ul.directorio_list button{
cursor:pointer !important;
}


.panel .nav_filter .nav_wrapper{
   /*fix a un borde blanco que aparecia abajo de la barra en el direcotrio*/
   border:0px;
}


.firma_user_name{
    /*fix nombres*/
    max-width:none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/*pagination mobile*/
@media (max-width: 799px) {

    .pagination{
        margin-bottom: 100px !important;
    }

}


.ais-Pagination-list li {
	display: inline-block;
	margin-right: 10px;
}

.ais-Pagination-link {
	display: block;
	border-radius: 50% !important;
	border: solid 1px #5b5b5b !important;
	background: transparent;
	padding: 0;
	width: 32px;
	height: 32px;
	line-height: 30px;
	vertical-align: middle;
	text-align: center;
	font-size: 13px;
	color: #5b5b5b !important;
	font-weight: 600;
}
@media (max-width: 799px) {
    .ais-Pagination{
        margin-top: 20px;
        margin-bottom: 100px;
    }
    .ais-Pagination-list li {
	    margin-right: 2px;
    }
    .ais-Pagination-link {
        font-size: 10px;
        width: 20px;
        height: 20px;
	    line-height: 20px;
    }
}

.ais-Pagination-item--selected a{
    background:#5b5b5b !important;
    color:white !important;
}

</style>