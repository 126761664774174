<template>
<div>
    <div class="mobile-header">
        <a @click="menuToggle()" class="nav_menu_button_mobile">
            <font-awesome-icon :icon="['fas','bars']" />
        </a>
        <img src="@/assets/images/logo.svg" alt="Logo" class="mobile-logo">
    </div>
    <!-- <div v-if="!$util.isCustomizer()" class="horizontal_nav" >
        <a @click="menuToggle()" class="nav_menu_button">
        <font-awesome-icon :icon="['fas','bars']" /> 
        </a>
        <ul class="nav_center">
            <template v-if="$router.history.current.name.includes('notifications')">
            <li :class="{ active: $route.hash=='#favs'}"><a @click="$router.push('/notifications#favs')" ><font-awesome-icon   :icon="['fas','heart']"/> </a></li>
            <li :class="{ active: $route.hash=='#shares'}"><a @click="$router.push('/notifications#shares')" ><font-awesome-icon  :icon="['fas','paper-plane']" /> </a></li>
            <li :class="{ active: $route.hash=='#inbox'}"><a @click="$router.push('/notifications#inbox')" ><font-awesome-icon  :icon="['fas','envelope']" /> </a></li>
            <li :class="{ active: $route.hash=='#visits'}"><a @click="$router.push('/notifications#visits')" ><font-awesome-icon  :icon="['fas','eye']" /> </a></li>
            </template>
            <template v-else-if="$router.history.current.name.includes('partner')">
            <li v-if="!search_open"><a @click="searchToggle()" ><font-awesome-icon  :icon="['fas','search']"/><small>{{$t('general.buscar')}}</small></a></li>
            <li v-else><a @click="searchToggle()"><font-awesome-icon  icon="window-close"/> </a></li>
            </template>
            <template v-else-if="$router.history.current.name.includes('account')">
                <li :class="{ active: $route.hash=='#profile'}"><a @click="$router.push('/account#profile')" ><font-awesome-icon :icon="['fas','user']"/><small>{{$t('micuenta.micuenta')}}</small></a></li>
                <li :class="{ active: $route.hash=='#billing'}"><a @click="gotoPortal()"><font-awesome-icon :icon="['fab','stripe']"/><small>{{$t('micuenta.miplan')}}</small></a></li>
            </template>
            <template v-else-if="$router.history.current.name.includes('directory')">
            <li v-if="!search_open"><a @click="searchToggle()" ><font-awesome-icon  :icon="['fas','search']"/><small>{{$t('general.buscar')}}</small></a></li>
            <li v-else><a @click="searchToggle()" ><font-awesome-icon  icon="window-close"/></a></li>
            </template>
            <template v-else>
            <li :class="{ active: $router.history.current.name === 'sector-sector-landings' || $router.history.current.name === 'sector-sectors-landings'}"><a @click="toggleRoute($util.DASHHOME_url())">
                <Icon icon="ph:magic-wand-light"/>
                                <small>{{$t('leftnav.milinc')}}</small></a></li>
            <li :class="{ active: $router.history.current.name == 'stats-landings' }"><a @click="enable_Route('/stats/landings')">
                <Icon icon="ph:chart-line-light"/>
                                <small>{{$t('micuenta.mismetricas')}}</small></a></li>
            <li :class="{ active: $router.history.current.name == 'leads-landings' }"><a @click="enable_Route('/leads/landings')">
                <Icon icon="ph:users-three-light"/>
                                <small>{{$t('leftnav.leads')}}</small></a></li>
            </template>
        </ul>
    </div> -->
    <div class="content_overlay" v-show="isMenuOpen" @click="menuToggle()"  />
    <leftColMobile :profile="getUser" v-if="$util.isCustomizer()" />
</div>

</template>
    

<script>
import {auth,functions,db } from '@/services/firebaseConfig.js';
import leftColMobile from '@/layouts/main_Customizer/leftCol_Mobile.vue'

export default {
    props:['value','comp'],
    data(){
        return{
            search_open:false,
            signature:false,
            getUser:this.$store.state.userProfile,
            loading:false,
            isMenuOpen: false
        }
    },
    created() {
        var dis = this
        this.$bus.$on('switchComp', comp => {
            comp=comp.replace('preview', "leftCol");
            if(comp=='leftColSobre')dis.editor=true
            else dis.editor=false
        });
    },
    methods:{

        enable_Route(route){
            if(this.$util.isTrialOrPro(this.$modal,route))this.toggleRoute(route)
        },

        toggleRoute(route){
            this.$router.push(route)
            this.$util.closeAllDivs()
        },
        async gotoPortal(){
            var dis=this
            this.$router.push('/account#portal')
            this.loading=true

            const get_stripe_portal = functions.httpsCallable('get_stripe_portal');
            await get_stripe_portal({customer_id:this.getUser.stripe_details.stripe_customer_id,return_url:process.env.URL+'account#profile',})
            .then(function(result) {
                window.location.href=result.data.url
                dis.loading_dash=false
            }).catch(error => {
                console.log(error)
            })
        },
        customToggle(custom){
            this.$util.toggleDiv(custom);

        },
        rightColToggle() {
            this.signature=!this.signature
            this.$util.toggleDiv("rightcol_open");
		},
		searchClose(){
            this.$util.toggleDiv("search_open");
		},
        searchToggle() {
            this.search_open=!this.search_open
            this.$util.toggleDiv("search_open");
        },
        menuToggle(){
            this.isMenuOpen = !this.isMenuOpen;
            this.$util.toggleDiv("menu_open");
        },

        closeMenu() {
            if (this.isMenuOpen) {
                this.isMenuOpen = false;
                document.body.classList.remove("menu_open");
            }
        },
        isRight(){
			if(process.client){
                return document.body.classList.contains('rightcol_open');
			}
        }
    },
	components:{
		leftColMobile
	}
}
</script>

<style scoped>
.content_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;
}

.nav_menu_button_mobile  {
  display: none;
}

.mobile-logo {
  display: none;
}

@media (max-width: 1198px) {
  .horizontal_nav {
    display: none;
  }

  .mobile-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 10px;
    background: white;
    z-index: 100;
    display: flex;
    align-items: center;
    height: 50px;
  }

  .nav_menu_button_mobile {
    display: block !important;
    padding: 8px;
    font-size: 20px;
    background: transparent;
    border: none;
    cursor: pointer;
  }

  .mobile-logo {
    display: block;
    height: 30px;
    margin: 0 auto;
  }

  .panelContent {
    margin-top: 60px;
  }
}


li.active a small,
li.active a svg {
  color: grey !important;
}
</style>