<template>
<div class="main_content ">
           <div class="nav_filter">
                <div class="nav_wrapper">
                    <div class="form-group group_filtro" style="min-width: 0;">
                        <div class="input-group">
                            <div class="input-group-append">
                                <select class="form-control" id="comboMostrar" v-model='orderBy'>
                                    <option value='relevance'>{{$t('filtros.relevance')}}</option>
                                    <option value='byname'>{{$t('filtros.alfa')}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="wrapper pt20 pb20 pl20 pr20 scolable" >
                
                <ais-infinite-hits v-if="orderBy=='byname'" :transform-items="orderbyName">
                    <template slot-scope="{ items }">
                        <h6 class="text_results mb-0" v-if="items.length>0">{{$t('general.mostrando')}} {{items.length}} {{$t('general.results')}} </h6> 
                        <h6 v-else class="text_results mb-0">{{$t('general.nose')}}</h6> 
                        <ul class="partners_list">
                            <template v-for="item in items" >
                                <li :key="item.uid" >
                                    <mPartner  :user="item" :users="items"/> 
                                </li>
                            </template>
                        </ul>
                        
                    </template>
                </ais-infinite-hits>
                <ais-infinite-hits v-else >
                    <template slot-scope="{ items }">
                        <h6 class="text_results mb-0" v-if="items.length>0">{{$t('general.mostrando')}} {{items.length}} {{$t('general.results')}} </h6> 
                        <h6 v-else class="text_results mb-0">{{$t('general.nose')}}</h6> 
                        <ul class="partners_list">
                            <template v-for="item in items" >
                                <li :key="item.uid" >
                                    <mPartner   :user="item" :users="items"/> 
                                </li>
                            </template>
                        </ul>
                        <div v-if="items.length>0" class="mas_contenedor">
                            <button class="firma_url manito w100"  @click="refineNext">
                                {{$t('general.showmore')}}
                            </button>
                        </div>
                        
                    </template>
                </ais-infinite-hits>
            </div>
</div>
</template>



<script>

import mPartner from "@/layouts/main_Directorio/main/miniPartner.vue";

import algoliasearch from 'algoliasearch/lite';

export default {
    props:['users'],
    data() {
        return{
            dirUser:null,
            laLista:this.users,
            //varios
            orderBy:this.$store.state.orderBy,
            loading:false,
        }
    },
    methods: {
        orderbyName(items) {
            return items.sort((a, b) => {
                let ca=a.account_details.account_name
                let cb=b.account_details.account_name
                return ca.localeCompare(cb)
            })
            
        },
    },
    computed:{
        partners_filtered(){
            let devolver=this.laLista
    
            ///Ojo cuando quieras ordenar
            if(this.orderBy=='byname'){
                devolver=devolver.sort((a, b) => a.account_details.account_name.localeCompare(b.account_details.account_name))
            }else if(this.orderBy=='relevance'){
                devolver=devolver.sort((a, b) =>  a.id - b.id)
            }
            return devolver
        }
    },
    components:{
         mPartner
    },
    watch:{
        orderBy(val){
            this.$store.state.orderBy=val
        }
    }
}

</script>


<style>

/*Para que aparezca la manito en todos*/
ul.directorio_list li,ul.directorio_list button{
cursor:pointer !important;
}


.panel .nav_filter .nav_wrapper{
   /*fix a un borde blanco que aparecia abajo de la barra en el direcotrio*/
   border:0px;
}


.firma_user_name{
    /*fix nombres*/
    max-width:none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/*pagination mobile*/
@media (max-width: 799px) {

    .pagination{
        margin-bottom: 100px !important;
    }
    .group_filtro{
        padding-top: 60px!important;
    }
}
</style>