<template>
<div class="main_content ">
            <div class="nav_filter">
                <div class="nav_wrapper">s
                    <div  class="form-group group_filtro" style="min-width: 0;">
                        <div class="input-group">
                            <label class="mb-0 mt5 mr-2">{{$t('filtros.mostrar')}}</label>
                            <div class="input-group-append">
                                <select class="form-control" id="comboMostrar" v-model='orderBy'>
                                    <option value='relevance'>{{$t('filtros.relevance')}}</option>
                                    <option value='byname'>{{$t('filtros.alfa')}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>


            <div class="wrapper pt20 pb20 pl20 pr20 scolable" >
                    <h6 class="text_results mb-0" v-if="users_filtered.length>0">{{$t('general.mostrando')}} {{users_filtered.length}} {{$t('general.results')}} </h6> 
                    <h6 v-else class="text_results mb-0">{{$t('general.nose')}}</h6> 
                 
                    <ul class="directorio_list">
                        <template v-for="user in users_filtered">{{user.id}}
                            <li :key="user.uid" v-if="user.linckards && user.activeLincs<=1 "  >
                                <mLinckard :linc='user.linckards[0]'  :user="user"  :users="users_filtered" /> 
                            </li>
                            <li :key="user.uid"  v-else-if="(user.sectors && user.sectors.length>0)  && user.activeLincs>=1 ">
                                <mDirectory :user='user' :users="users_filtered" /> 
                            </li>
                            <li :key="user.uid" v-else-if="user.activeLincs>1 "  >
                                <mMulticard :user='user'  :users="users_filtered" /> 
                            </li>

                        </template>
                         
                    </ul>

            </div>
</div>
</template>



<script>

import mLinckard from "@/layouts/main_Directorio/main/miniLinckard.vue";
import mPartner from "@/layouts/main_Directorio/main/miniPartner.vue";
import mDirectory from "@/layouts/main_Directorio/main/miniDirectory.vue";
import mMulticard from "@/layouts/main_Directorio/main/miniMulticard.vue";

export default {
    props:['users'],
    data() {
        return{
            abuscar:this.users,
            //varios
            orderBy:this.$store.state.orderBy,
            loading:false,
        }
    },
    computed:{
        users_filtered(){
            let devolver=this.abuscar
            if(this.orderBy=='byname'){
                devolver=devolver.sort((a, b) => a.account_details.account_name.localeCompare(b.account_details.account_name))
            }else if(this.orderBy=='relevance'){
                devolver=devolver.sort((a, b) =>  a.id - b.id)
            }
            return devolver
        }
    },
    components:{
        mLinckard,  mPartner,mDirectory,mMulticard
    }
}

</script>


<style>

/*Para que aparezca la manito en todos*/
ul.directorio_list li,ul.directorio_list button{
cursor:pointer !important;
}


.panel .nav_filter .nav_wrapper{
   /*fix a un borde blanco que aparecia abajo de la barra en el direcotrio*/
   border:0px;
}


.firma_user_name{
    /*fix nombres*/
    max-width:none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/*pagination mobile*/
@media (max-width: 799px) {

    .pagination{
        margin-bottom: 100px !important;
    }
    .group_filtro{
        padding-top: 60px!important;
    }
}
</style>