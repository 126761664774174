<template>
    <client-only>
        <div id="panel" class="panel">
            <barraMensaje />
            <div id="LeftNav">
                <div class="left_nav custom_scrollbar main_menu ">
                    <div class="nav_wrapper">
                        <profile :user="getUser" />
                        <ul class="primary_menu" style="margin-bottom: 0px;">
                            <folder @click.native="folderActive = 1" items=1 :open="folderActive === 1"
                                text="Business Cards" icon="ph:user-list-light">
                                <entry text="Dashboard" icon="ph:magic-wand-light" route="/sectors/bcs" />
                                <!--entry text="Email Signatures" icon="ph:signature-light" />
                                <entry text="Leads" icon="ph:users-three-light" />
                                <entry text="Stats" icon="ph:chart-line-light" /-->
                            </folder>
                            <folder @click.native="folderActive = 2" items=1 :open="folderActive === 2"
                                text="Products & Services" icon="ph:toolbox-light">
                                <entry text="Dashboard" icon="ph:magic-wand-light" route="/sectors/landings"/>
                                <!--entry text="Leads" icon="ph:users-three-light" />
                                <entry text="Stats" icon="ph:chart-line-light" /-->
                            </folder>
                            <folder @click.native="folderActive = 3" items=1 :open="folderActive === 3" text="Events"
                                icon="ph:calendar-check-light">
                                <entry text="Dashboard" icon="ph:magic-wand-light" route="/sectors/events"/>
                                <!--entry text="Leads" icon="ph:users-three-light" />
                                <entry text="Stats" icon="ph:chart-line-light" /-->
                            </folder>
                            <folder @click.native="folderActive = 4" items=1 :open="folderActive === 4" text="Loyalty"
                                icon="ph:trophy-light">
                                <entry text="Dashboard" icon="ph:magic-wand-light" route="/sectors/loyalty"/>
                                <!--entry text="Members" icon="ph:users-three-light" />
                                <entry text="Stats" icon="ph:chart-line-light" /-->
                            </folder>
                            <!--folder @click.native="folderActive = 5" items=3 :open="folderActive === 5" text="Superuser"
                                icon="ph:gear-six-light">
                                <entry text="Original Platform" icon="ph:airplay-light" route="/sector/sectors/landings" />
                                <entry text="Admin" icon="ph:gear-light" route="/admin#fast" />
                                <entry text="Directory" icon="ph:user-list-light" route="/directory" />
                            </folder-->
                        </ul>
                        <ul class="secondary_menu" style="margin-top: 10px; margin-left: 0px;">
                            <li @click="salir();"><a>{{ $t('general.logout') }}</a></li>
                        </ul>
                        <div class="nav_foot">
                            <slider src="https://estudioefex.com.ar/linkcard/banner-menu-principal" />
                        </div>
                    </div>
                </div>
                <bottomNav v-if="!hidebottom" />
            </div>
            <modals />
            <!-- div class="center">
                <vs-dialog not-close auto-width not-padding v-model="active">
                    <iframe src="https://estudioefex.com.ar/linkcard/home-popup/" width="500" height="450" frameborder="0"
                        scrolling="no" style="border-radius: 25px;"></iframe>
                </vs-dialog>
            </div -->
            <div class="panelContent miLinckard">
                <div class="main_content account">

                    <div class="row ml-0 mr-0">
                        <div v-if="!singleColumn" class="col-lg-4 pl-0 pr-0" style="min-width: 300px;">
                            <slot name="settingsToolbar"></slot>
                            <div class="edicion_container scolable custom_scrollbar" style="background-color: #f6f6f6">
                                <slider :src="topBanner" />
                                <slot name="settings"></slot>
                                <slider :src="bottomBanner" />
                            </div>
                        </div>
                        <div class="col pl-0 pr-0">
                            <slot name="bodyToolbar"></slot>
                            <div v-if="loading"
                                style="	background-color: #ededed; display: flex; justify-content: center; align-items: center; height: 110%;">
                                <img :src="require('@/assets/images/loading.gif')" width="200">
                            </div>
                            <div v-else class="edicion_wrapper" style="">
                                <slot></slot>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </client-only>
</template>
  
<script>
import { db, functions } from "@/services/firebaseConfig.js";
import LeftNav from "@/components/LeftNav.vue";
import modals from "@/components/modals/modals.vue";
import barraMensaje from "@/components/barraMensaje.vue";
import bottomNav from "@/components/bottomNav.vue"
import profile from "@/components/menu/profile.vue"
import folder from "@/components/menu/folder.vue"
import entry from "@/components/menu/entry.vue"
import slider from "@/components/external/slider.vue"

export default {
    middleware: "auth-check",
    props: ['loading', 'topBanner', 'bottomBanner', 'singleColumn'],
    head() {
        return this.$util.simpleMetaHead();
    },
    data() {
        return {
            active: true,
            folderActive: 1,
            loading_stats: true,
            getUser: this.$store.state.userProfile,
            username: this.$store.state.userProfile.username,
            dontEdit: true,
            email: "",
            loading: false,
            url_loading: false,
            //stats
            shares: [],
            opens: [],
            totals: [],
            clicks: [],
            visits: [],
            linea: [],
            activeLinkcard: this.$route.query.linkcard_id
                ? this.$route.query.linkcard_id
                : "all",
        };
    },
    mounted() {
        let dis = this;
        this.$util.initPage(this.getUser, null, true, this.$validator);
        this.loadStats(this.activeLinkcard);

        setTimeout(() => {
            dis.abrirModal();
        }, 500);
    },
    methods: {
        openLanding() {
            window.open(this.$util.landingURL(), '_blank')
        },
        iralhome(mode) {
            this.$store.state.mode = mode;
            this.$router.push(this.$util.DASHHOME_url(mode))
        },
        enable_Route(route) {
            if (this.$util.isTrialOrPro(this.$modal, route)) this.toggleRoute(route)
        },
        toggleRoute(route) {
            this.$router.push(route)
            this.$util.closeAllDivs()
        },
        getLincImage_error(event) {
            console.log(event)
            event.target.src = this.$util.getLincImage(this.preview, null)
        },
        salir() {
            var dis = this;
            if (this.$store.getters.hasBeenEdited) {
                this.$modal.show('dialog', {
                    title: dis.$t('notis.atencion'), text: dis.$t('notis.seguroque'),
                    buttons: [
                        { title: dis.$t('notis.si'), handler: () => { dis.logout() } },
                        { title: dis.$t('general.no') }
                    ]
                })
            } else this.logout()
        },
        logout() {
            this.$util.logout()
        },
        getImage(size) {
            var img = this.getUser ? this.getUser.account_details.account_picture : this.$util.getImage(this.getUser)
            if (!img) return require("@/assets/images/profile_nophoto_color.png");
            else if (img && img.obj) return img.obj;
            else if (img) return this.$util.removeToken(img, size)
        },
        abrirModal() {
            this.$util.isTrialOrPro(this.$modal, "stats", true);
            this.$modal.show('modal-news', { info: {} })

        },
        openLinkcard() {
            window.open(
                this.$util.cardURL() +
                this.getUser.username +
                "/" +
                this.activeLinkcard,
                "_blank"
            );
        },

        async loadStats(val = "all") {
            var dis = this;
            dis.loading_stats = true;
            console.log("val", val);
            const stats = functions.httpsCallable("stats");
            let data = await stats({ val: val, uid: dis.getUser.uid }).then((res) => {
                return res;
            });
            data = data.data;
            console.log(data);
            dis.shares = [];
            dis.opens = [];
            dis.totals = [];
            dis.clicks = [];
            dis.visits = [];
            dis.linea = [];
            dis.linea = data.linea;

            for (let i in data.totals)
                dis.totals[data.totals[i][0]] = data.totals[i][1];
            for (let i in data.clicks)
                dis.clicks[data.clicks[i][0]] = data.clicks[i][1];
            for (let i in data.shares)
                dis.shares[data.shares[i][0]] = data.shares[i][1];
            for (let i in data.visits)
                dis.visits[data.visits[i][0]] = data.visits[i][1];
            for (let i in data.opens) dis.opens[data.opens[i][0]] = data.opens[i][1];

            dis.loading_stats = false;
        },

        async loadStats_old(val = "all") {
            if (val != "all") val = parseInt(val);
            var dis = this;
            dis.loading_stats = true;
            dis.shares = [];
            dis.opens = [];
            dis.clicks = [];
            dis.totals = [];
            dis.visits = [];

            let count_1 = [];

            let ref = db.collection("stats");
            ref = ref.where("from.uid", "==", this.getUser.uid);
            if (val != "all") ref = ref.where("from.linkcard_id", "==", val);
            ref = ref.orderBy("date", "asc");
            const querySnapshot = await ref.get();
            console.log("Se trajeron ", querySnapshot.size);
            for (let i in querySnapshot.docs) {
                let doc = querySnapshot.docs[i];
                let objeTo = doc.data();
                let type = objeTo.type;
                let subtype = objeTo.subtype;

                if (type == "click") {
                    if (!dis.clicks[subtype]) dis.clicks[subtype] = 0;
                    dis.clicks[subtype]++;
                } else if (type == "share") {
                    if (subtype == "wpweb" || subtype == "whatsappnative")
                        subtype = "whatsapp";
                    if (!dis.shares[subtype]) dis.shares[subtype] = 0;
                    dis.shares[subtype]++;
                } else if (type == "open") {
                    let subtype = objeTo.subtype;
                    if (!dis.opens[subtype]) dis.opens[subtype] = 0;
                    dis.opens[subtype]++;
                } else if (type == "visit") {
                    let x = this.$util.getNombreMes(new Date(objeTo.date).getMonth() + 1);
                    count_1[x] = (count_1[x] || 0) + 1;

                    let subtype = objeTo.subtype;
                    if (!dis.visits[subtype]) dis.visits[subtype] = 0;
                    dis.visits[subtype]++;
                }
                if (!dis.totals[type]) dis.totals[type] = 0;
                dis.totals[type]++;
            }
            console.log(dis.totals);
            Object.keys(count_1).forEach((key) => {
                dis.linea.push([key, count_1[key]]);
            });
            dis.loading_stats = false;
        },
    },
    computed: {
        cardVal() {
            var dis = this;
            let index = this.getUser.linckards.findIndex(function (i) {
                return i.id == dis.activeLinkcard;
            });
            return this.getUser.linckards[index];
        },
        linksFiltradas() {
            let devol = [];
            let lincs = this.getUser.linckards;
            devol.push({ id: "all", name: this.$t("filtros.todos") });
            for (let i in lincs)
                devol.push({
                    id: lincs[i].id,
                    name: lincs[i].info.nombre_landing
                        ? lincs[i].info.nombre_landing
                        : lincs[i].info.nombre,
                });
            return devol;
        },
    },
    watch: {
        activeLinkcard(val) {
            this.loadStats(val);
        },
    },
    components: {
        LeftNav,
        modals,
        barraMensaje,
        bottomNav,
        profile,
        folder,
        entry, slider
    },
};
</script>
<style scoped>
.panel .left_nav .primary_menu .leftsinbor>li>a {
    border: 0px !important;
}

.panel .left_nav .social_list a {
    font-size: 16px;
    margin: 0 10px;
}

.panel .left_nav {
    z-index: 4000 !important;
}

@media (max-width: 799px) {
    .panel .left_nav .secondary_menu>li>a {
        font-size: 14px !important;
        padding: 2px !important;
    }

    .main_content {
        padding-top: 60px!important;
    }
}

.usr_btn.active {
    background: #828282;
    border-color: #828282 !important;
    color: white !important;
}

a.open {
    color: grey !important;
}

li.active a {
    color: black !important;
    font-weight: 700 !important;
}
</style>